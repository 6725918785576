<!-- @Author: zhaoyang -->
<!-- @Date: 2024-06-21 10:37:05 -->
<!-- @Last Modified by: zhaoyang -->
<!-- @Last Modified time: 2024-06-21 10:37:05 -->

<template>
    <div class="page-content delete-user-submit">
        <ec-form
            v-if="formOptions"
            ref="form"
            class="ec-form"
            :options="formOptions"
            @validateChange="onValidateChange"
        >
            <template slot="code.after">
                <ec-btn
                    class="code-btn"
                    data-upload="SendCodeBtn"
                    free-size
                    :text="time > 0 ? `${time}S` : getI18n('sendCode')"
                    :disabled="time > 0"
                    @click="onSendCode"
                />
            </template>
        </ec-form>
        <div class="mt-20 affect-container">
            <div class="affect-title">
                {{ getI18n('cancelAffectTitle') }}
            </div>
            <div class="affect-content">
                {{ getI18n('cancelAffectContent') }}
            </div>
        </div>
        <ec-btn
            class="mt-20"
            data-upload="LoginBtn"
            :text="getI18n('nextStep')"
            :disabled="hasError"
            @click="handleClick"
        />
        <ec-modal
            :visible="showModal"
            class="captcha-modal"
            @close="showModal = false"
        >
            <h3>{{ getI18n('codeInput') }}</h3>
            <div class="img-wrapper">
                <img
                    class="captcha-img"
                    :src="captchaImg"
                >
                <img
                    class="refresh"
                    src="../register-login/img/refresh.svg"
                    @click="fetchCaptchaImg"
                >
            </div>
            <vcode-input
                ref="vcodeInput"
                @finish="checkCode"
            />
            <div
                class="close"
                @click="showModal = false"
            >
                x
            </div>
        </ec-modal>
    </div>
</template>

<script>
import {Dialog} from 'vant';
import {mapActions} from 'vuex';

import EcForm from 'easycash-h5/common/component/ec-form';
import EcModal from 'easycash-h5/common/component/ec-modal';
import DeleteUserApi from 'easycash-h5/common/resource/delete-user';
import UserApi from 'easycash-h5/common/resource/user';

import vcodeInput from '../register-login/component/vcode-input';
import {getOptions} from './constant/form-options';
import {DeleteStatusMap} from './constant/index';

export default {
    name: 'DeleteUserSubmit',

    title() {
        return this.getI18n('title');
    },

    components: {
        EcForm,
        vcodeInput,
        EcModal
    },

    data() {
        return {
            hasError: false,
            time: 0,
            verificationPurpose: 'DELETE_USER',
            notifType: 'SMS',
            showModal: false,
            captchaKey: '',
            captchaImg: '',
            formOptions: null,
            // 客服电话
            customerSserviceTelephone: null
        };
    },

    computed: {
        mobileNumber() {
            const {mobileNumber} = this.$route.query;

            return mobileNumber;
        }
    },

    mounted() {
        const {hasIdentity} = this.$route.query;
        this.formOptions = getOptions({
            defaultMobile: this.mobileNumber,
            // 如果上一页页面设置为boolean类型，且是通过路由方法跳转过来的，则获取的值为boolean
            // 如果直接刷新当前页面，则获取的值为string
            idNoRequired: String(hasIdentity) === 'true'
        });
        // 提前获取客服电话
        this.getContact();
    },

    methods: {
        ...mapActions('baseData', ['getUserInfo']),
        // 获取客服电话
        async getContact() {
            const {
                data: {body}
            } = await DeleteUserApi.getContact({});
            this.customerSserviceTelephone = body.telephone;
        },
        // 立即注销
        async handleClick() {
            try {
                const identityNumber = this.$refs.form.getFieldValue('idNo');
                const verificationCode = this.$refs.form.getFieldValue('code');
                const {purposeWarnCode} = this.$route.query;
                const params = {
                    identityNumber,
                    verificationCode,
                    mobileNumber: this.mobileNumber,
                    purposeWarnCode
                };

                const {
                    data: {body}
                } = await DeleteUserApi.identityAndOtp(params);

                // body 不为 true 时不做任何展示，与端上一致
                if (body === true) {
                    this.onConfirmFirst();
                }
            } catch (e) {
                // console.log(e);
            }
        },
        // 第一步确认弹窗
        onConfirmFirst() {
            Dialog.confirm({
                title: this.getI18n('warmTips'),
                message: this.getI18n('warmTipsMessage'),
                confirmButtonText: this.getI18n('continueUseing'),
                cancelButtonText: this.getI18n('confirmCancel')
            })
                .then(() => {
                    this.routeToHome();
                })
                .catch(() => {
                    this.onConfirmSecond();
                });
        },
        // 第二部确认弹窗
        onConfirmSecond() {
            Dialog.confirm({
                title: this.getI18n('importantTips'),
                message: this.getI18n('importantTipsMessage', [this.customerSserviceTelephone]),
                confirmButtonText: this.getI18n('continueUseing'),
                cancelButtonText: this.getI18n('confirmCancel')
            })
                .then(() => {
                    this.routeToHome();
                })
                .catch(async () => {
                    const {reasons, note} = this.$route.query;
                    if (!reasons || !this.mobileNumber) {
                        return;
                    }

                    try {
                        const params = {
                            mobileNumber: this.mobileNumber,
                            reasons: JSON.parse(decodeURIComponent(reasons)),
                            note: note ? decodeURIComponent(note) : ''
                        };
                        const {
                            data: {body}
                        } = await DeleteUserApi.deleteApply(params);
                        if (body.deleteStatus === DeleteStatusMap.COMPLETE) {
                            this.$toast.success(this.getI18n('cancelSuccess'));
                            setTimeout(() => {
                                this.routeToRegisterLogin();
                            }, 2000);
                        } else if (body.deleteStatus === DeleteStatusMap.PENDING) {
                            this.disableDelete();
                        }
                    } catch (e) {
                        // console.log(e);
                    }
                });
        },
        disableDelete() {
            Dialog.alert({
                title: this.getI18n('safetyTips'),
                message: this.getI18n('safetyTipsMessage'),
                confirmButtonText: this.getI18n('safetyTipsOk')
            }).finally(() => {
                this.$toast.success(this.getI18n('submitSuccess'));
                setTimeout(() => {
                    this.routeToHome();
                }, 2000);
            });
        },
        async routeToHome() {
            await this.getUserInfo();
            this.$router.replace({
                name: 'home'
            });
        },
        routeToRegisterLogin() {
            location.replace('/register-login');
        },
        onValidateChange({hasError}) {
            this.hasError = hasError;
        },
        startTimer() {
            const timestamp = Date.now();
            this.time = 60;
            this.interval = setInterval(() => {
                if (this.time <= 0) {
                    this.time = 0;

                    return this.clear();
                }

                this.time = 60 - Math.round((Date.now() - timestamp) / 1000);
            }, 1000);
        },
        clear() {
            if (this.interval) clearInterval(this.interval);
        },
        getI18n(key, template = []) {
            return this.$t(`deleteUserSubmitPage.${key}`, template);
        },
        async onSendCode() {
            const {notifType, verificationPurpose} = this;

            const {errMsg, errCode} = await this.preCheck({
                mobileNumber: this.mobileNumber,
                notifType,
                verificationPurpose
            });
            if (!errMsg) {
                this.$toast.success(this.getI18n('sendCodeSuccess'));

                return this.startTimer();
            }

            // 错误处理逻辑
            this.showModal = false;
            if (errCode === 30017) {
                // 图形验证码逻辑
                await this.fetchCaptchaImg();

                return (this.showModal = true);
            }
        },
        async fetchCaptchaImg() {
            const {
                data: {body: captchaKey}
            } = await UserApi.generateCaptcha();
            this.captchaKey = captchaKey;
            this.captchaImg = await UserApi.getCaptchaImage({captchaKey});
        },
        async checkCode(captcha) {
            this.captcha = captcha;
            const {captchaKey, verificationPurpose, preCheck, notifType} = this;

            const data = {
                mobileNumber: this.mobileNumber,
                captcha,
                captchaKey,
                verificationPurpose,
                notifType
            };
            const {errMsg, errCode} = await preCheck(data);

            if (!errMsg) {
                this.showModal = false;

                return this.startTimer();
            }

            // 错误处理逻辑
            this.$refs.vcodeInput.init();
            if (errCode === 3009) {
                this.fetchCaptchaImg();
            }
        },
        preCheck(data) {
            return DeleteUserApi.sendVerificationWithoutCaptcha(data).catch(
                ({data: {status: {code, detail} = {}} = {}}) => ({
                    errCode: code,
                    errMsg: detail,
                    data: {body: {}}
                })
            );
        }
    }
};
</script>

<style lang="scss" scoped>
.delete-user-submit {
    .ec-form {
        margin-top: 0.17rem;

        .before {
            height: 0.18rem;
            font-size: 14px;
            font-weight: bold;
            color: #999;
            line-height: 0.18rem;
            margin-right: 0.05rem;
        }

        .code-btn {
            width: 0.4rem;
        }
    }

    .disabled {
        color: #a3acbc;
    }

    .affect-container {
        font-size: 14px;

        .affect-title {
            color: #f3b747;
        }

        .affect-content {
            white-space: pre-line;
        }
    }
}

.captcha-modal {
    left: 0;

    ::v-deep {
        .ec-dialog-body {
            padding: 7% 11.5% 6.5%;
            position: relative;
        }
    }

    h3 {
        font-size: 18px;
        font-weight: 600;
        color: #333;
        text-align: center;
    }

    .img-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-left: 6.94vw;

        .captcha-img {
            width: 49vw;
            max-height: 20vw;
        }

        .refresh {
            width: 6.4vw;
        }
    }

    .close {
        position: absolute;
        top: 0;
        right: 0;
        font-size: 18px;
        padding: 0.15rem 0.3rem;
        cursor: pointer;
    }
}
</style>
