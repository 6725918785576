var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "register-login" },
    [
      _c("img", {
        staticClass: "header-img",
        attrs: { src: _vm.config.banner },
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "form-container" },
        [
          _c("h3", { staticClass: "form-title" }, [
            _vm._v(
              "\n            " + _vm._s(_vm.getI18n("formTitle")) + "\n        "
            ),
          ]),
          _vm._v(" "),
          _c(
            "ec-form",
            {
              ref: "form",
              staticClass: "ec-form",
              attrs: { options: _vm.$options.FormOptions },
              on: { validateChange: _vm.onValidateChange },
            },
            [
              _c(
                "template",
                { slot: "code.after" },
                [
                  _c("ec-btn", {
                    staticClass: "code-btn",
                    attrs: {
                      "data-upload": "SendCodeBtn",
                      "free-size": "",
                      text:
                        _vm.time > 0 ? _vm.time + "S" : _vm.getI18n("sendCode"),
                      disabled: _vm.mobileError || _vm.time > 0,
                    },
                    on: { click: _vm.onSendCode },
                  }),
                ],
                1
              ),
            ],
            2
          ),
          _vm._v(" "),
          _vm.showMore
            ? _c(
                "div",
                {
                  staticClass: "more-methods",
                  class: { disabled: _vm.isTiming },
                  on: { click: _vm.moreMethods },
                },
                [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.getI18n("moreMethods")) +
                      "\n        "
                  ),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c("ec-btn", {
            staticClass: "mt-30",
            attrs: {
              "data-upload": "LoginBtn",
              text: _vm.getI18n("nextStep"),
              disabled: _vm.hasError,
            },
            on: { click: _vm.onConfirm },
          }),
          _vm._v(" "),
          _c(
            "van-checkbox",
            {
              staticClass: "mt-10",
              model: {
                value: _vm.privacyCheck,
                callback: function ($$v) {
                  _vm.privacyCheck = $$v
                },
                expression: "privacyCheck",
              },
            },
            [
              _c("div", { staticClass: "privacy-container" }, [
                _c("span", [_vm._v(_vm._s(_vm.getI18n("privacy")))]),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    staticClass: "main-green",
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.onPrivacyCheck(0)
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n                    " +
                        _vm._s(_vm.getI18n("privacyList")[0]) +
                        ",\n                "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    staticClass: "main-green",
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.onPrivacyCheck(1)
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n                    " +
                        _vm._s(_vm.getI18n("privacyList")[1]) +
                        "\n                "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("span", [_vm._v(_vm._s(_vm.getI18n("and")))]),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    staticClass: "main-green",
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.onPrivacyCheck(2)
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n                    " +
                        _vm._s(_vm.getI18n("privacyList")[2]) +
                        "\n                "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("span", [_vm._v(" " + _vm._s(_vm.getI18n("fromEasycash")))]),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "van-checkbox",
            {
              model: {
                value: _vm.smsProtocolCheck,
                callback: function ($$v) {
                  _vm.smsProtocolCheck = $$v
                },
                expression: "smsProtocolCheck",
              },
            },
            [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.getI18n("smsProtocol")) +
                  "\n        "
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "md" },
            [_c("yqg-md-preview", { attrs: { "md-text": _vm.text.content } })],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "ec-overlay",
        {
          attrs: {
            "close-type": "bottom",
            title: _vm.getI18n("moreMethodsTitle"),
            "wapper-style": {
              padding: "0.2rem",
            },
          },
          model: {
            value: _vm.show,
            callback: function ($$v) {
              _vm.show = $$v
            },
            expression: "show",
          },
        },
        _vm._l(_vm.getI18n("methodsList"), function (item, index) {
          return _c(
            "div",
            {
              key: item,
              staticClass: "item-method",
              style: { "margin-top": index === 0 ? "0.2rem" : "0.1rem" },
              on: {
                click: function ($event) {
                  return _vm.onMethodClick(index)
                },
              },
            },
            [_vm._v("\n            " + _vm._s(item) + "\n        ")]
          )
        }),
        0
      ),
      _vm._v(" "),
      _c(
        "ec-overlay",
        {
          attrs: {
            "close-type": "bottom",
            "wapper-style": {
              transform: "translateY(-0.5rem)",
            },
            "close-style": {
              bottom: "-0.7rem",
            },
          },
          model: {
            value: _vm.showDownload,
            callback: function ($$v) {
              _vm.showDownload = $$v
            },
            expression: "showDownload",
          },
        },
        [
          _c("div", { staticClass: "c-inner" }, [
            _c("div", { staticClass: "c-content" }, [
              _vm._v(
                "\n                Akun Anda sudah terdaftar, silahkan buka melalui aplikasi Easycash\n            "
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "c-btn", on: { click: _vm.onDownload } }, [
              _vm._v("\n                Open\n            "),
            ]),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "ec-modal",
        {
          staticClass: "captcha-modal",
          attrs: { visible: _vm.showModal },
          on: {
            close: function ($event) {
              _vm.showModal = false
            },
          },
        },
        [
          _c("h3", [_vm._v(_vm._s(_vm.getI18n("codeInput")))]),
          _vm._v(" "),
          _c("div", { staticClass: "img-wrapper" }, [
            _c("img", {
              staticClass: "captcha-img",
              attrs: { src: _vm.captchaImg },
            }),
            _vm._v(" "),
            _c("img", {
              staticClass: "refresh",
              attrs: { src: require("./img/refresh.svg") },
              on: { click: _vm.fetchCaptchaImg },
            }),
          ]),
          _vm._v(" "),
          _c("vcode-input", {
            ref: "vcodeInput",
            on: { finish: _vm.checkCode },
          }),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "close",
              on: {
                click: function ($event) {
                  _vm.showModal = false
                },
              },
            },
            [_vm._v("\n            x\n        ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }