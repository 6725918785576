import { registerLoginMobileRule } from 'easycash-h5/common/constant/rules';
import { numFormat } from 'easycash-h5/common/util/format';
export const getOptions = ({ defaultMobile = '', idNoRequired }) => {
    // TODO: 定义组件类型并复用
    const fieldDefs = [
        {
            label: ({ ctx }) => {
                return `deleteUserSubmitPage.${ctx.notifType === 'SMS' ? 'mobile' : 'whatsAppAccount'}`;
            },
            field: 'mobile',
            format: {
                format: numFormat
            },
            type: 'tel',
            rules: registerLoginMobileRule,
            useCleave: {
                blocks: [4, 4, 4, 2]
            },
            disabled() {
                return true;
            },
            required: true,
            defaultValue: defaultMobile,
        },
        {
            label: 'deleteUserSubmitPage.code',
            field: 'code',
            format: {
                format: numFormat
            },
            type: 'tel',
            maxlength: 6,
            required: true,
            rules: [
                {
                    validator: (value) => (value === null || value === void 0 ? void 0 : value.length) === 6,
                    message: 'deleteUserSubmitPage.codeTip'
                }
            ]
        }
    ];
    if (idNoRequired) {
        fieldDefs.splice(1, 0, {
            label: 'nik',
            field: 'idNo',
            // raw: false,
            useCleave: {
                blocks: [6, 6, 4]
            },
            type: 'tel',
            rules: [
                {
                    validator: value => (value === null || value === void 0 ? void 0 : value.length) >= 16,
                    message: 'deleteUserSubmitPage.ktpLenTip'
                }
            ],
            format: {
                format: numFormat
            },
            required: true
        });
    }
    return {
        fieldDefs
    };
};
