var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-content delete-user-submit" },
    [
      _vm.formOptions
        ? _c(
            "ec-form",
            {
              ref: "form",
              staticClass: "ec-form",
              attrs: { options: _vm.formOptions },
              on: { validateChange: _vm.onValidateChange },
            },
            [
              _c(
                "template",
                { slot: "code.after" },
                [
                  _c("ec-btn", {
                    staticClass: "code-btn",
                    attrs: {
                      "data-upload": "SendCodeBtn",
                      "free-size": "",
                      text:
                        _vm.time > 0 ? _vm.time + "S" : _vm.getI18n("sendCode"),
                      disabled: _vm.time > 0,
                    },
                    on: { click: _vm.onSendCode },
                  }),
                ],
                1
              ),
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "mt-20 affect-container" }, [
        _c("div", { staticClass: "affect-title" }, [
          _vm._v(
            "\n            " +
              _vm._s(_vm.getI18n("cancelAffectTitle")) +
              "\n        "
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "affect-content" }, [
          _vm._v(
            "\n            " +
              _vm._s(_vm.getI18n("cancelAffectContent")) +
              "\n        "
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("ec-btn", {
        staticClass: "mt-20",
        attrs: {
          "data-upload": "LoginBtn",
          text: _vm.getI18n("nextStep"),
          disabled: _vm.hasError,
        },
        on: { click: _vm.handleClick },
      }),
      _vm._v(" "),
      _c(
        "ec-modal",
        {
          staticClass: "captcha-modal",
          attrs: { visible: _vm.showModal },
          on: {
            close: function ($event) {
              _vm.showModal = false
            },
          },
        },
        [
          _c("h3", [_vm._v(_vm._s(_vm.getI18n("codeInput")))]),
          _vm._v(" "),
          _c("div", { staticClass: "img-wrapper" }, [
            _c("img", {
              staticClass: "captcha-img",
              attrs: { src: _vm.captchaImg },
            }),
            _vm._v(" "),
            _c("img", {
              staticClass: "refresh",
              attrs: { src: require("../register-login/img/refresh.svg") },
              on: { click: _vm.fetchCaptchaImg },
            }),
          ]),
          _vm._v(" "),
          _c("vcode-input", {
            ref: "vcodeInput",
            on: { finish: _vm.checkCode },
          }),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "close",
              on: {
                click: function ($event) {
                  _vm.showModal = false
                },
              },
            },
            [_vm._v("\n            x\n        ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }