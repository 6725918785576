<!-- @Author: bowang  -->
<!-- @Date: 2023-05-15 10:50:03  -->
<!-- @Last Modified by:   bowang  -->
<!-- @Last Modified time: 2023-05-15 10:50:03  -->

<template>
    <div class="register-login">
        <img
            class="header-img"
            :src="config.banner"
        >

        <div class="form-container">
            <h3 class="form-title">
                {{ getI18n('formTitle') }}
            </h3>
            <ec-form
                ref="form"
                class="ec-form"
                :options="$options.FormOptions"
                @validateChange="onValidateChange"
            >
                <template slot="code.after">
                    <ec-btn
                        class="code-btn"
                        data-upload="SendCodeBtn"
                        free-size
                        :text="time > 0 ? `${time}S` : getI18n('sendCode')"
                        :disabled="mobileError || time > 0"
                        @click="onSendCode"
                    />
                </template>
            </ec-form>
            <div
                v-if="showMore"
                class="more-methods"
                :class="{disabled: isTiming}"
                @click="moreMethods"
            >
                {{ getI18n('moreMethods') }}
            </div>
            <ec-btn
                class="mt-30"
                data-upload="LoginBtn"
                :text="getI18n('nextStep')"
                :disabled="hasError"
                @click="onConfirm"
            />
            <van-checkbox
                v-model="privacyCheck"
                class="mt-10"
            >
                <div class="privacy-container">
                    <span>{{ getI18n('privacy') }}</span>
                    <span
                        class="main-green"
                        @click.stop="onPrivacyCheck(0)"
                    >
                        {{ getI18n('privacyList')[0] }},
                    </span>
                    <span
                        class="main-green"
                        @click.stop="onPrivacyCheck(1)"
                    >
                        {{ getI18n('privacyList')[1] }}
                    </span>
                    <span>{{ getI18n('and') }}</span>
                    <span
                        class="main-green"
                        @click.stop="onPrivacyCheck(2)"
                    >
                        {{ getI18n('privacyList')[2] }}
                    </span>
                    <span> {{ getI18n('fromEasycash') }}</span>
                </div>
            </van-checkbox>
            <van-checkbox
                v-model="smsProtocolCheck"
            >
                {{ getI18n('smsProtocol') }}
            </van-checkbox>

            <div class="md">
                <yqg-md-preview
                    :md-text="text.content"
                />
            </div>
        </div>
        <ec-overlay
            v-model="show"
            close-type="bottom"
            :title="getI18n('moreMethodsTitle')"
            :wapper-style="{
                padding: '0.2rem'
            }"
        >
            <div
                v-for="(item, index) in getI18n('methodsList')"
                :key="item"
                class="item-method"
                :style="{'margin-top': index===0 ? '0.2rem' : '0.1rem'}"
                @click="onMethodClick(index)"
            >
                {{ item }}
            </div>
        </ec-overlay>
        <ec-overlay
            v-model="showDownload"
            close-type="bottom"
            :wapper-style="{
                transform: 'translateY(-0.5rem)'
            }"
            :close-style="{
                bottom: '-0.7rem'
            }"
        >
            <div class="c-inner">
                <div class="c-content">
                    Akun Anda sudah terdaftar, silahkan buka melalui aplikasi Easycash
                </div>
                <div
                    class="c-btn"
                    @click="onDownload"
                >
                    Open
                </div>
            </div>
        </ec-overlay>
        <ec-modal
            :visible="showModal"
            class="captcha-modal"
            @close="showModal = false"
        >
            <h3>{{ getI18n('codeInput') }}</h3>
            <div class="img-wrapper">
                <img
                    class="captcha-img"
                    :src="captchaImg"
                >
                <img
                    class="refresh"
                    src="./img/refresh.svg"
                    @click="fetchCaptchaImg"
                >
            </div>
            <vcode-input
                ref="vcodeInput"
                @finish="checkCode"
            />
            <div
                class="close"
                @click="showModal = false"
            >
                x
            </div>
        </ec-modal>
    </div>
</template>

<script>
import {mapGetters, mapMutations, mapActions} from 'vuex';

import {getUserId} from '@shared/client/util/user-token';

import {isProd} from 'ssr-common/constant/config';
import {genWebDeviceToken} from 'ssr-common/util/browser-util';
import prefetch from 'ssr-common/vue/mixin/server-prefetch';

import EcForm from 'easycash-h5/common/component/ec-form';
import EcModal from 'easycash-h5/common/component/ec-modal';
import EcOverlay from 'easycash-h5/common/component/ec-overlay';
import {initGtagAds} from 'easycash-h5/common/constant/config';
import businessData from 'easycash-h5/common/mixin/business-data';
import initFirebase from 'easycash-h5/common/mixin/init-firebase';
import Operation from 'easycash-h5/common/resource/operation';
import RegisterLoginApi from 'easycash-h5/common/resource/register-login';
import UserApi from 'easycash-h5/common/resource/user';
import {getClientId} from 'easycash-h5/common/util/gtag';

import vcodeInput from './component/vcode-input';
import FormOptions from './constant/form-options';
import {methodsMap} from './constant/method-map';
import {EASYCASH_IDN_REGISTER, EASYCASH_TERMS_OF_USE, ELECTRONIC_SIGNATURE_AUTHORIZATION} from './constant/protocol-key';

export default {
    name: 'RegisterLogin',

    FormOptions,

    components: {
        EcForm,
        EcModal,
        vcodeInput,
        EcOverlay
    },

    mixins: [businessData, prefetch('text'), initFirebase],

    inject: ['uploadClickEvent'],

    data() {
        return {
            showMore: false,
            showDownload: false,
            show: false,
            notifType: 'SMS',
            hasError: false,
            mobileError: false,
            jointMobile: '',
            privacyCheck: true,
            smsProtocolCheck: true,
            showModal: false,
            captcha: '',
            captchaKey: '',
            captchaImg: '',
            clientID: '',
            time: 0,
            verificationPurpose: 'REGISTER_OR_LOGIN',
            protocolList: [
                EASYCASH_IDN_REGISTER,
                EASYCASH_TERMS_OF_USE,
                ELECTRONIC_SIGNATURE_AUTHORIZATION
            ],
        };
    },

    computed: {
        ...mapGetters('channelName', ['channelName']),

        isJointLogin() {
            const {channelName, jwt_token} = this.$route.query;

            return ['indosat', 'indosatBimaPlus'].includes(channelName) && jwt_token;
        },

        isTiming() {
            return this.time > 0;
        },

        config() {
            const {channelName} = this.$route.query;

            const config = this.getBusinessData('register')[channelName];

            return config ? config : this.getBusinessData('register')['default'];
        }
    },

    async mounted() {
        const {isJointLogin} = this;
        const {channelName, jwt_token: jwtToken} = this.$route.query;

        if (isJointLogin) {
            try {
                const {
                    data: {
                        body
                    }
                } = await RegisterLoginApi.getMobileNumber({
                    params: {jwtToken}
                });

                const mobileNumber = body?.mobileNumber ?? '';
                this.jointMobileNumber = mobileNumber;
                const {form} = this.$refs;
                form.setFieldValue('mobile', mobileNumber);
                form.$refs.input.find(({field}) => field === 'mobile').validateNoInputVal();
                this.onSendCode();
            } catch (error) {
                //ignore
            }
        }

        this.initFirebase();
        this.setChannelName(channelName);
        initGtagAds(this);
        this.clientID = await getClientId();
    },

    beforeDestroy() {
        this.clear();
    },

    methods: {
        ...mapMutations('channelName', ['setChannelName']),
        ...mapActions('baseData', ['getUserInfo']),

        async initFetch({header}) {
            const key = this.config.textKey;

            const {
                data: {
                    body
                } = {}
            } = await this.requestHelper(Operation.getStaticTextByKey({
                params: {key},
                headers: header
            }));

            return body ?? {};
        },

        onDownload() {
            const host = isProd() ? 'https://easycash.id' : 'https://test-indo-easycash.fintopia.tech';

            location.href = host + '/redirect-store';
        },

        moreMethods() {
            if (this.isTiming) return;
            this.show = true;
        },

        onMethodClick(index) {
            this.notifType = methodsMap[index];
            this.show = false;
            this.onSendCode();
        },

        async onConfirm() {
            const {
                privacyCheck,
                smsProtocolCheck,
                hasError,
                protocolList,
                $dialog,
                $route: {query},
                isJointLogin
            } = this;

            const dialogMessge = [];
            if (!privacyCheck) {
                dialogMessge.push(`<div>${this.getI18n('dialogMessge', this.getI18n('privacyList').map((item, index) => `<a href="/protocol?key=${protocolList[index]}" style="display: inline-block;">${item}</a>`))}</div>`);
            }

            if (!smsProtocolCheck) {
                dialogMessge.push(`<div>${this.getI18n('agreeSmsProtocol')}</div>`);
            }

            if (dialogMessge.length) {
                try {
                    await $dialog.confirm({
                        message: dialogMessge.join(''),
                        confirmButtonText: this.getI18n('agreeEnter'),
                        cancelButtonText: this.getI18n('thinkAgain')
                    });
                    this.privacyCheck = true;
                    this.smsProtocolCheck = true;
                    this.uploadClickEvent('Agree');
                } catch (error) {
                    this.uploadClickEvent('ThinkAbout');
                    if (!privacyCheck) return;
                }
            }

            if (!hasError) {
                const {mobile, code} = this.$refs.form.getValues();
                const channelName = this.channelName || query.channelName;
                const params = {
                    mobileNumber: mobile,
                    verificationCode: code,
                    selectedSDKType: 'IDN_YQD',
                    channelName: `web_${channelName || 'null'}`,
                    deviceToken: genWebDeviceToken(),
                    appInstanceId: this.clientID
                };
                const {
                    data: {body, status} = {}
                } = await RegisterLoginApi.registerOrLogin(params).catch(err => err);

                if (!status?.code) {
                    this.$sensors?.login?.(getUserId());

                    await this.loginAfterRequestPermission();

                    if (body?.mode === 'register') {
                        this.$gtag.event('web_sign_up_web');
                    } else {
                        this.$gtag.event('web_login_web');
                    }

                    await UserApi.acceptPromotionPush({acceptPromotionPush: this.smsProtocolCheck});

                    // 登录成功
                    const routeName = body?.mode === 'register' ? 'auth' : 'home';
                    const noReturn = body?.mode === 'register' ? 'true' : 'false';

                    this.uploadClickEvent('LoginSuccess');
                    this.onCompleteLog();

                    // 联合登录逻辑，第二次登录去下载app
                    // if (isJointLogin && body?.mode !== 'register') {
                    //     this.showDownload = true;

                    //     return;
                    // }

                    if (body.nextPage === 'SECURE_LIVING') {
                        this.$router.push({
                            name: 'RegisterLoginStatus',
                            query: {
                                noReturn,
                                channelName,
                                mobileNumber: mobile
                            }
                        });

                        return;
                    }

                    await this.getUserInfo();

                    if (channelName === 'deleteUser') {
                        this.$router.replace({
                            name: 'deleteUser',
                            query: {channelName}
                        });

                        return;
                    }

                    this.$router.replace({
                        name: routeName,
                        query: {noReturn}
                    });
                } else if (status?.code === 80042) {
                    this.uploadClickEvent('RegisterdInApp', {
                        mobileNumber: mobile,
                        channelName
                    });
                }
            }

            this.$refs.form.validator();
        },

        onValidateChange({hasError, errorMap: {mobile}}) {
            this.hasError = hasError;
            this.mobileError = !!(mobile && mobile?.length);
        },

        async onSendCode() {
            const {notifType, verificationPurpose} = this;
            const mobileNumber = this.$refs.form.getFieldValue('mobile');

            const {errMsg, errCode} = await this.preCheck({
                mobileNumber,
                notifType,
                verificationPurpose
            });
            if (!errMsg) {
                this.$toast.success(this.getI18n('sendCodeSuccess'));

                return this.startTimer();
            }

            // 错误处理逻辑
            this.showModal = false;
            if (errCode === 30017) {
                // 图形验证码逻辑
                await this.fetchCaptchaImg();

                return this.showModal = true;
            }
        },

        onPrivacyCheck(index) {
            const key = this.protocolList[index];
            this.$router.push({name: 'protocol', query: {key}});
        },

        preCheck(data) {
            return RegisterLoginApi.sendVerificationWithoutCaptcha(data)
                .catch((
                    {data: {status: {code, detail} = {}} = {}}
                ) => ({
                    errCode: code,
                    errMsg: detail,
                    data: {body: {}}
                }));
        },

        async fetchCaptchaImg() {
            const {data: {body: captchaKey}} = await UserApi.generateCaptcha();
            this.captchaKey = captchaKey;
            this.captchaImg = await UserApi.getCaptchaImage({captchaKey});
        },

        async checkCode(captcha) {
            this.captcha = captcha;
            const {
                captchaKey,
                verificationPurpose,
                preCheck,
                notifType
            } = this;
            const mobileNumber = this.$refs.form.getFieldValue('mobile');

            const data = {
                mobileNumber,
                captcha,
                captchaKey,
                verificationPurpose,
                notifType
            };
            const {errMsg, errCode} = await preCheck(data);

            if (!errMsg) {
                this.showModal = false;

                return this.startTimer();
            }

            // 错误处理逻辑
            this.$refs.vcodeInput.init();
            if (errCode === 3009) {
                this.fetchCaptchaImg();
            }
        },

        startTimer() {
            const timestamp = Date.now();
            this.time = 60;
            this.interval = setInterval(() => {
                if (this.time <= 0) {
                    this.time = 0;
                    this.showMore = true;

                    return this.clear();
                }

                this.time = 60 - Math.round((Date.now() - timestamp) / 1000);
            }, 1000);
        },

        clear() {
            if (this.interval) clearInterval(this.interval);
        },

        onCompleteLog() {
            try {
                window.otag('event', 'registration');
                window.fbq('trackCustom', 'CompleteRegister');
                this.$gtag.event('register_login');
            } catch (error) {
                // ignore
            }
        },

        getI18n(key, template = []) {
            return this.$t(`registerLoginPage.${key}`, template);
        }
    }
};
</script>

<style lang="scss" scoped>
@import "easycash-h5/common/style/variables.scss";

.register-login {
    height: 100vh;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    background-color: #f4f4f4;

    .header-img {
        height: 2.7rem;
    }

    .privacy-container {
        width: 117%;
        display: flex;
        flex-wrap: wrap;

        span {
            display: inline-block;

            &.main-green {
                margin-left: 0.5em;
                margin-right: 0.5em;
            }
        }
    }

    .form-container {
        background-color: #f4f4f4;
        margin-top: -0.3rem;
        padding: 0.2rem 0.15rem 0.3rem;
        border-top-left-radius: 16px;
        border-top-right-radius: 16px;

        .ec-form {
            margin-top: 0.17rem;

            .before {
                height: 0.18rem;
                font-size: 14px;
                font-weight: bold;
                color: #999;
                line-height: 0.18rem;
                margin-right: 0.05rem;
            }

            .code-btn {
                width: 0.4rem;
            }
        }

        .more-methods {
            margin-top: 0.14rem;
            text-align: right;
            color: #3fca58;
            font-size: 0.12rem;
            font-weight: 400;
            line-height: 0.16rem;
        }

        .md {
            margin-top: 0.1rem;
            box-sizing: border-box;
            padding: 0.153rem;
            border-radius: 4px;
            background-color: #fff;

            ::v-deep .yqg-md-preview {
                p {
                    margin: 0;
                    font-size: 10px;
                    color: $textGray;
                }
            }
        }

        .disabled {
            color: #a3acbc;
        }
    }

    .checkbox-container {
        margin-top: 0.1rem;
        display: flex;

        .privacy-text {
            margin-left: 0.07rem;
            color: $textGray;
            font-size: 12px;
            transform-origin: 0 0 0;
            transform: scale(0.85);
        }
    }

    .item-method {
        height: 0.44rem;
        text-align: center;
        font-size: 0.14rem;
        font-weight: 400;
        line-height: 0.44rem;
        border: 1px #ccc solid;
        border-radius: 0.04rem;

        &:active {
            border-color: #3fca58;
            background-color: rgba(63, 202, 88, 0.1);
        }
    }
}

.c-inner {
    .c-content {
        font-family: Helvetica;
        font-size: 0.14rem;
        font-weight: 400;
        line-height: 0.18rem;
        text-align: center;
        box-sizing: border-box;
        padding: 0.2rem;
        color: #333;
    }

    .c-btn {
        border-top: 1px solid #dedede;
        height: 0.64rem;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: Helvetica;
        font-size: 0.16rem;
        font-weight: 700;
        line-height: 0.24rem;
        color: #3fca58;
    }
}

.captcha-modal {
    left: 0;

    ::v-deep {
        .ec-dialog-body {
            padding: 7% 11.5% 6.5%;
            position: relative;
        }
    }

    h3 {
        font-size: 18px;
        font-weight: 600;
        color: #333;
        text-align: center;
    }

    .img-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-left: 6.94vw;

        .captcha-img {
            width: 49vw;
            max-height: 20vw;
        }

        .refresh {
            width: 6.4vw;
        }
    }

    .close {
        position: absolute;
        top: 0;
        right: 0;
        font-size: 18px;
        padding: 0.15rem 0.3rem;
        cursor: pointer;
    }
}

h3,
p {
    margin: 0;
}
</style>
