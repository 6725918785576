/*
 * @Author: zhaoyang
 * @Date: 2024-03-22 12:52:00
 * @Last Modified by: zhaoyang
 * @Last Modified time: 2024-03-22 12:54:59
 */
import { isServer } from 'ssr-common/constant/config';
export default {
    methods: {
        getBusinessData(key) {
            let businessData = {};
            if (isServer()) {
                businessData = this.$ssrContext.businessData;
            }
            else {
                businessData = window.__BUSINESS_DATA__; // eslint-disable-line
            }
            if (!key) {
                return businessData;
            }
            return businessData === null || businessData === void 0 ? void 0 : businessData[key];
        }
    }
};
